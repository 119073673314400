import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getBackLink, getHeaderTitles, getNextQuizLink, getNextTitle } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage, mySubpage }) => {
  const questionNumber = 1
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
      }}
    >
      <Quiz
        quizNumber={"TWO"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={"What does SBC stand for?"}
        answers={[
          "Standard Behavior Code",
          "Strict Behavior Code",
          "Serious Behavior Commands",
          "Serious Behavior Consequences",
        ]}
        correctAnswerNumber={1}
        answerDetail={
          "Strict Behavior Code is an important part of creating a strong team environment to help girls understand how the program will keep them safe, physically, emotionally, and psychologically."
        }
        nextLink={nextLink}
      ></Quiz>
    </Layout>
  )
}

export default withProgress(Page, 2, 6)
